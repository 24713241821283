import React from 'react';
import { Router, Route, Switch, HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import store from './store';
import axios from 'axios';
import { changeAppInfo, getNavInfo, setSeoInfo, setSubjectInfos } from './store/actions';
import { getConfig, saveConfig } from '../src/utils/util';
import { AsyncLoadable, CloudUtils } from 'cloud-xinyi';
import {DES} from '../src/utils/encrypt';
const LocalIndex = AsyncLoadable(() => import('./views/index'));

const history = createBrowserHistory();
const baseUrl = '';

// app info
const { getQuery } = CloudUtils;
//let { key } = getQuery(`?${history.location.hash.split('?')[1]}`);
// console.log("key",key);

axios.get("/Services/AppConfigSvr.svc/GetConfigInfo", {
  // params: {
  //   key: "shanxiwanbao",
  // }
}).then((res) => {


  const appId = res.data.Data.AppId;
  const ModilarId = res.data.Data.ModilarId;
  const ProjectId = res.data.Data.ProjectId;
  let data = {
    appId: appId,
    ModilarId: ModilarId,
    ProjectId:ProjectId,
  };
  saveConfig(data);
  store.dispatch(changeAppInfo(data));

  // 导航栏
  axios.get("/Services/HomeBorderInfoSvr.svc/GetHomeBorderInfo", {
    params: {
      appId: appId,
      styleId: 1,
      template: "123"
    }
  }).then((res) => {
    let data = res.data;
    console.log("tok2", res.data);
    if (data && data.Data) {
      /**
       * 改进，一次dispatch 分发多个action
       */
      //栏目

      let ModilarConfigs = data.Data.ModilarConfigs;
      store.dispatch(getNavInfo({ ModilarConfigs }));
      //seo信息
      let SEOInfos = data.Data.SEOInfos;
      store.dispatch(setSeoInfo({ SEOInfos }));
      //底部版权申明
      let SubjectInfos = data.Data.SubjectInfos;
      store.dispatch(setSubjectInfos({ SubjectInfos }));
    }
  });
});






function App() {
  return (
    <Provider store={store}>
      <HashRouter>
        <Switch>
          <Route path={`${baseUrl}/`} component={LocalIndex} />
        </Switch>
      </HashRouter>
    </Provider>
  );
}

export default App;
