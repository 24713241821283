export const ADD_TODO = 'ADD_TODO';
// 改变应用公共信息
export const CHANGE_APP_INFO = 'CHANGE_APP_INFO';
// 获取导航栏
export const GET_NAV_INFO = 'GET_NAV_INFO';
// 设置seo信息
export const SET_SEO_INFO = 'SET_SEO_INFO';
// 设置底部版权申明
export const SET_Subject_INFO = 'SET_Subject_INFO';
// 设置首页广告
export const SET_HomeAdv_INFO = 'SET_HomeAdv_INFO';
// 设置首页广告展示
export const SET_ShowAdv_INFO = 'SET_ShowAdv_INFO';
//更新头像
export const SET_Refresh_Avatar = 'SET_Refresh_Avatar';

