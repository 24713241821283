import { CHANGE_APP_INFO,GET_NAV_INFO,SET_SEO_INFO,SET_Subject_INFO,SET_HomeAdv_INFO,SET_ShowAdv_INFO,SET_Refresh_Avatar} from './actionTypes';
import { combineReducers } from 'redux';

// 应用信息
const appInfo = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_APP_INFO:
      return {
        ...state,
        ...action.info
      };
    default:
      return state;
  }
};
//导航栏信息
const navInfo = (state = {}, action) => {
  switch (action.type) {
    case GET_NAV_INFO:
      return {
        ...state,
        ...action.info
      };
    default:
      return state;
  }
};
//SEO信息
const seoInfo = (state = {}, action) => {
  switch (action.type) {
    case SET_SEO_INFO:
      return {
        ...state,
        ...action.info
      };
    default:
      return state;
  }
};
//底部版权信息
const subjectInfo = (state = {}, action) => {
  switch (action.type) {
    case SET_Subject_INFO:
      return {
        ...state,
        ...action.info
      };
    default:
      return state;
  }
};
//设置首页广告
const homeAdvInfo = (state = {}, action) => {
  switch (action.type) {
    case SET_HomeAdv_INFO:
      return {
        ...state,
        ...action.info
      };
    default:
      return state;
  }
};
//设置首页广告展示
const showAdv = (state = {}, action) => {
  switch (action.type) {
    case SET_ShowAdv_INFO:
      return {
        ...state,
        ...action.info
      };
    default:
      return state;
  }
};
//设置首页广告展示
const refreshAvatarInfo = (state = {}, action) => {
  switch (action.type) {
    case SET_Refresh_Avatar:
      return {
        ...state,
        ...action.info
      };
    default:
      return state;
  }
};


export default combineReducers({
  appInfo,navInfo,seoInfo,subjectInfo,homeAdvInfo,showAdv,refreshAvatarInfo
});
