import { CloudUtils } from 'cloud-xinyi';
import { createBrowserHistory } from 'history';
const { getQuery } = CloudUtils;


export const getConfig = (props) => {
    const history = createBrowserHistory();
    if (props) {
        console.log(props);
    }
    let { appId, ModilarId } = getQuery(`?${history.location.hash.split('?')[1]}`);
    if (appId && ModilarId) {
        console.log("调试信息4",appId ==''?"555":appId,ModilarId);
        return {
            appId: appId,
            ModilarId: ModilarId
        };
    }
    if (props && props.appId && props.ModilarId) {
        console.log("调试信息44",appId ==''?"666":props.appId,props.ModilarId);
        return {
            appId: props.appId,
            ModilarId: props.ModilarId
        };
    }
    let data = JSON.parse(localStorage.getItem("sxwbConfig"));
    if (data && data.appId && data.ModilarId) {
        console.log("调试信息444",appId ==''?"777":data.appId,data.ModilarId);
        return {
            appId: data.appId,
            ModilarId: data.ModilarId
        };
    }
    console.log("调试信息5",appId,ModilarId);
    return {
        appId: "",
        ModilarId: ""
    };
};
export const saveConfig = (props) => {
    if (props) {
        console.log(props);
    }
    if (props && props.appId && props.ModilarId) {
        const data = {
            appId: props.appId,
            ModilarId: props.ModilarId
        };
        localStorage.setItem("sxwbConfig", JSON.stringify(data));
    }
};
export const getModilarId = () => {
    let myhistory = createBrowserHistory();
    return myhistory.location.hash.split('ModilarId=')[1];

};

export const saveToken = (token) => {

    sessionStorage.setItem("sxwb_token",token);

};

export const getToken = () => {
    return sessionStorage.getItem("sxwb_token");

};
export const clearToken = () => {
    return sessionStorage.clear();

};