var CryptoJS = require("crypto-js");



export const DES = (string) => {
    let key = "apipartyxinhuaapp1234567";
    let base64 = CryptoJS.enc.Utf8.parse(key);
    let encrypt = CryptoJS.TripleDES.encrypt(string, base64, {
        iv: CryptoJS.enc.Utf8.parse(''), //iv偏移量
        //mode: CryptoJS.mode.CBC, //CBC模式
        mode: CryptoJS.mode.ECB, //ECB模式
        padding: CryptoJS.pad.Pkcs7 //padding处理
    });
    let encryptData = encrypt.toString(); //加密完成后，转换成字符串
    //console.log("DES", encryptData);
    return encryptData;
};
//解密
export const decrypt = (string) => {
    let key = "apipartyxinhuaapp1234567";
    let base64 = CryptoJS.enc.Utf8.parse(key);
    let decrypt = CryptoJS.TripleDES.decrypt(string, base64, {
        iv: CryptoJS.enc.Utf8.parse(''),
        //mode: CryptoJS.mode.CBC,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    //解析数据后转为UTF-8
   
    let decryptData = decrypt.toString(CryptoJS.enc.Utf8); //加密完成后，转换成字符串
    //console.log("decrypt", decryptData);
    return decryptData;
};
export const MD5 = (string) => {
    let md5 = CryptoJS.MD5(string);
    let encryptData  = md5.toString();
    //console.log("MD5", encryptData);
    return encryptData;
};