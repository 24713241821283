import { CHANGE_APP_INFO,GET_NAV_INFO,SET_SEO_INFO,SET_Subject_INFO,SET_HomeAdv_INFO,SET_ShowAdv_INFO,SET_Refresh_Avatar } from './actionTypes';

// 改变应用信息
export const changeAppInfo = info => ({
  type: CHANGE_APP_INFO,
  info
});
// 获取导航栏
export const getNavInfo = info => ({
  type: GET_NAV_INFO,
  info
});
// 设置seo信息
export const setSeoInfo = info => ({
  type: SET_SEO_INFO,
  info
});
// 设置底部版权申明
export const setSubjectInfos = info => ({
  type: SET_Subject_INFO,
  info
});
// 设置首页广告
export const setHomeAdvInfo = info => ({
  type: SET_HomeAdv_INFO,
  info
});
// 是否展示首页头部
export const showAdvSet = info => ({
  type: SET_ShowAdv_INFO,
  info
});
// 登陆/上传头像 更新头像
export const refreshAvatar = info => ({
  type: SET_Refresh_Avatar,
  info
});

